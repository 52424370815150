import * as Yup from "yup"
import React, { useState } from "react"
import { Button, CustomInput } from "reactstrap"
import { Formik, Form } from "formik"
import first from "lodash/first"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"

import DangerModal from "../components/common/modals/danger"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FormikInput } from "../components/common/forms/input"
import { QueryName } from "../hasura/queryNames"
import { capitalizeFirstLetter, postSlackMessage } from "../lib/helpers"

import {
  createCheckoutSessionAction,
  createCustomerPortalSessionAction,
  updateUserRoleAction,
  updateUserSettingsAction,
  userSelector,
  UserState,
} from "../hasura/slices/user"

export default function Settings() {
  const dispatch = useDispatch()

  const { accessToken, user, isTeacher, isAdmin, hasPremium, hasSchoolPremium, isQuerying }: UserState = useSelector(userSelector)

  const [isConvertingAccount, setIsConvertingAccount] = useState(false)

  const handleSubmit = (values: any) => {
    if (values.pauseForSummer) dispatch(postSlackMessage(`<!here> ${user?.email} paused subscription for summer`))

    dispatch(
      updateUserSettingsAction(
        accessToken!,
        user!.id,
        values.display_name,
        values.school,
        values.leaderboardsDisabled,
        values.autoHintingDisabled,
        values.aiReportSectionEnabled,
        values.soundEffectsEnabled,
        values.pauseForSummer
      )
    )
  }
  const cancelAt = hasPremium && first(user?.related_accounts?.stripe_profile?.subscriptions?.data)?.cancel_at

  const [current, updated] = isTeacher ? ["teacher", "student"] : ["student", "teacher"]
  const needsClassroom = updated === "teacher" && user?.classrooms.length === 0
  const convertCopy = {
    title: `Convert to ${capitalizeFirstLetter(updated)} Account`,
    button: `Convert to ${updated} account`,
    text: `Are you sure you want to convert this ${current} account to a ${updated} account? Any data associated with your account may be lost.\nAfter you click the button below, you will need to log back in to your account.`,
  }

  return (
    <Layout>
      <SEO title="Settings" />

      {user && (
        <div className="default-width-padding max-width-700px mx-auto">
          <h1 className="header page-top">My Settings</h1>

          <Formik
            // @ts-ignore
            onSubmit={handleSubmit}
            initialValues={{
              display_name: user?.display_name || "",
              school: user.group?.display_name || user?.school || "",
              email: (user?.email || "").replace("+dummy@playwordcraft.com", ""),
              leaderboardsDisabled: Boolean(user?.leaderboards_disabled === true),
              autoHintingDisabled: Boolean(user?.settings?.autoHintingDisabled === true),
              aiReportSectionEnabled: Boolean(user?.ai_report_section_enabled === true),
              soundEffectsEnabled: Boolean(user?.sound_effects_enabled === true),
              pauseForSummer: Boolean(user?.pause_subscription_in_summer === true),
            }}
            validationSchema={Yup.object().shape({
              display_name: Yup.string().max(50, "Must be under 50 characters.").required("Name cannot be blank."),
              school: Yup.string().max(50, "Must be under 50 characters."),
            })}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <div>
                  <p className="subheader">personal</p>

                  <div>
                    <FormikInput disabled={!isTeacher} label="Name" name="display_name" placeholder="My name..." type="text" />

                    <FormikInput disabled label="Email/username" name="email" type="text" />

                    {isTeacher && <FormikInput disabled={user.group} label="School" name="school" placeholder="My school name..." type="text" />}
                  </div>
                </div>

                {isTeacher && (
                  <div className="mt-4">
                    <p className="subheader">functionality</p>

                    <div className="mt-1">
                      <div className="d-flex align-items-center">
                        <p className="text-s m-0 min-width-250px">
                          Autohinting is <span className="bold">{!formik.values.autoHintingDisabled ? "enabled" : "disabled"}</span>
                        </p>

                        <CustomInput
                          id="autoHintingDisabled"
                          checked={!formik.values.autoHintingDisabled}
                          className="m-0"
                          role="button"
                          type="switch"
                          onChange={() => formik.setFieldValue("autoHintingDisabled", !formik.values.autoHintingDisabled)}
                        />
                      </div>

                      <p className="text-xs text--gray7 m-0">Hints assist students who are stuck, especially at early levels.</p>
                    </div>

                    <div className="mt-1">
                      <div className="d-flex align-items-center">
                        <p className="text-s m-0 min-width-250px">
                          AI next steps on reports is <span className="bold">{formik.values.aiReportSectionEnabled ? "enabled" : "disabled"}</span>
                        </p>

                        <CustomInput
                          id="aiReportSectionEnabled"
                          checked={formik.values.aiReportSectionEnabled}
                          className="m-0"
                          role="button"
                          type="switch"
                          onChange={() => formik.setFieldValue("aiReportSectionEnabled", !formik.values.aiReportSectionEnabled)}
                        />
                      </div>

                      <p className="text-xs text--gray7 m-0">AI next steps is an experimental feature.</p>
                    </div>

                    <div className="mt-1">
                      <div className="d-flex align-items-center">
                        <p className="text-s m-0 min-width-250px">
                          Leaderboards are <span className="bold">{!formik.values.leaderboardsDisabled ? "enabled" : "disabled"}</span>
                        </p>

                        <CustomInput
                          id="leaderboardsDisabled"
                          checked={!formik.values.leaderboardsDisabled}
                          className="m-0"
                          role="button"
                          type="switch"
                          onChange={() => formik.setFieldValue("leaderboardsDisabled", !formik.values.leaderboardsDisabled)}
                        />
                      </div>

                      <p className="text-xs text--gray7 m-0">School and worldwide leaderboards. Usernames are anonymized outside of your school.</p>
                    </div>

                    <div className="mt-1">
                      <div className="d-flex align-items-center">
                        <p className="text-s m-0 min-width-250px">
                          Sound effects are <span className="bold">{formik.values.soundEffectsEnabled ? "enabled" : "disabled"}</span>
                        </p>

                        <CustomInput
                          id="soundEffectsEnabled"
                          checked={formik.values.soundEffectsEnabled}
                          className="m-0"
                          role="button"
                          type="switch"
                          onChange={() => formik.setFieldValue("soundEffectsEnabled", !formik.values.soundEffectsEnabled)}
                        />
                      </div>

                      <p className="text-xs text--gray7 m-0">Sound effects indicate correct and incorrect answers during gameplay.</p>
                    </div>
                  </div>
                )}

                {(isTeacher || user.is_individual) && !hasSchoolPremium && (
                  <div className="mt-4">
                    <p className="subheader">billing</p>

                    {hasPremium && (
                      <div className="mt-2">
                        <div className="d-flex align-items-center">
                          <p className="text-s m-0 min-width-250px">
                            Pause for Summer is <span className="bold">{formik.values.pauseForSummer ? "enabled" : "disabled"}</span>
                          </p>

                          <CustomInput
                            id="pauseForSummer"
                            checked={formik.values.pauseForSummer}
                            className="m-0"
                            role="button"
                            type="switch"
                            onChange={() => formik.setFieldValue("pauseForSummer", !formik.values.pauseForSummer)}
                          />
                        </div>

                        <p className="text-xs text--gray7 m-0">Paused subscriptions will not incur billing for July or August.</p>
                      </div>
                    )}

                    <div className="mt-3 d-flex align-items-start">
                      <div className="">
                        <p className="text-s m-0">
                          Your current plan is:{" "}
                          <span className={`ml-1 bold ${hasPremium ? "text--purple" : "text--primary"}`}>{hasPremium ? "Premium" : "Basic"}</span>
                        </p>

                        {cancelAt && (
                          <p className="mt-1 text-xs text--gray7">Your premium subscription will end on {moment.unix(cancelAt).format("M/D/YYYY")}</p>
                        )}
                      </div>

                      <Button
                        onClick={() => {
                          const id = user.related_accounts!.stripe_profile!.id!
                          if (hasPremium) {
                            dispatch(createCustomerPortalSessionAction(accessToken!, id))
                          } else {
                            dispatch(createCheckoutSessionAction(accessToken!, id))
                          }
                        }}
                        color="primary"
                        className="ml-5"
                      >
                        {hasPremium ? "Update" : "Upgrade"} Subscription
                      </Button>
                    </div>
                  </div>
                )}

                {isTeacher && (
                  <Button
                    className="mt-4 min-width-200px"
                    type="submit"
                    disabled={!formik.isValid || isQuerying[QueryName.UpdateUserSettings]}
                    color="success"
                    size="lg"
                  >
                    Save changes
                  </Button>
                )}
              </Form>
            )}
          </Formik>

          <DangerModal
            body={convertCopy.text}
            confirm={() => dispatch(updateUserRoleAction(accessToken!, user.id, updated, needsClassroom))}
            confirmText={convertCopy.button}
            disabled={isQuerying[QueryName.UpdateUserRole]}
            isOpen={isConvertingAccount}
            setIsOpen={setIsConvertingAccount}
            title={convertCopy.title}
          />

          {!isAdmin && !user.is_individual && moment(user?.created_at).isAfter(moment().subtract(7, "day")) && (
            <Button
              className="mt-3 min-width-200px"
              disabled={isQuerying[QueryName.UpdateUserSettings]}
              color="danger"
              onClick={() => setIsConvertingAccount(true)}
            >
              {convertCopy.button}
            </Button>
          )}
        </div>
      )}
    </Layout>
  )
}
